import { render, staticRenderFns } from "./UnitDetails.vue?vue&type=template&id=719e0551&scoped=true"
import script from "./UnitDetails.vue?vue&type=script&lang=js"
export * from "./UnitDetails.vue?vue&type=script&lang=js"
import style0 from "./UnitDetails.vue?vue&type=style&index=0&id=719e0551&prod&lang=css"
import style1 from "./UnitDetails.vue?vue&type=style&index=1&id=719e0551&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "719e0551",
  null
  
)

export default component.exports